const quiz = [
  {
    question: '👴🏻 외모는 늙어보이지만 마음은 젊은 찬희의 나이는 몇살일까요? 👴🏻',
    useInput: true,
    answer: '22',
  },
  {
    question: '🍹 찬희가 가장 좋아하는 음료수는 무엇일까요? 🍹',
    choices: [
      '콜라',
      '닥터페퍼',
      '레몬에이드',
      '코코팜',
      '수돗물',
    ],
    answer: '레몬에이드',
  },
  {
    question: '🏃 찬희가 지금까지 해본 적이 없는 일은? 🏃',
    choices: [
      '외국인한테 물 달라고 바디랭귀지',
      '오렌지주스 박스 쓴채로 코딩하기',
      '기숙사 방에서 스테이크 구워먹기',
      '길가다가 로또 4등 당첨복권 줍기',
      '산에서 구조헬기 타고 내려오기',
    ],
    answer: '길가다가 로또 4등 당첨복권 줍기',
  },
  {
    question: '🖐️ 찬희가 가장 좋아하는 숫자는? 🖐️',
    choices: [
      7,
      12,
      29,
      144,
      1640,
    ],
    answer: 29,
  },
  {
    question: '🧳 찬희의 AB180 입사 이전 선택지 중 틀린 것은? 🧳',
    choices: [
      '용접공',
      '법무팀 비서',
      '코딩 학원 선생님',
      '광고 회사 디자이너',
      '기획자',
    ],
    answer: '광고 회사 디자이너',
  },
  {
    question: '⌨️ 찬희가 가장 좋아하는 회사는? ⌨️',
    choices: [
      'AB180',
      'Airbnb',
      'Amplitude',
      'AppsFlyer',
      'Braze',
    ],
    answer: 'Airbnb',
  },
  {
    question: '📛 찬희의 별명은 무엇일까요? (힌트: 일곱 글자) 📛',
    choices: [
      '차니차니찬차니',
    ],
    answer: '차니차니찬차니',
  },
  {
    question: '🚌 찬희는 평일에 도로 위에서 총 몇시간을 보낼까요? 🚌',
    choices: [
      '30분',
      '1시간',
      '2시간',
      '3시간',
      '4시간',
    ],
    answer: '4시간',
  },
  {
    question: '📍 찬희가 용인에서 가장 싫어하는 곳은? 📍',
    choices: [
      '에버랜드',
      '캐리비안베이',
      '한국민속촌',
      '자연휴양림',
      '백남준아트센터',
    ],
    answer: '에버랜드',
  },
  {
    question: '👀 찬희가 가장 자주 사용하는 이모지는? 👀',
    choices: [
      '🤔',
      '😎',
      '😇',
      '👊',
      '🔥',
    ],
    answer: '😇',
  },
];

export default quiz;
